<template>
  <div>
    <v-subheader class="font-italic font-weight-light">
      Zone Interne
    </v-subheader>

    <InternalZoneList
      :items="internalZoneList"
      @input="selectedCard"
      :returnObject="true"
      card-max-height="100"
      card-min-height="100"
      card-max-width="100"
      card-min-width="100"
    >
      <template v-slot:item="{ item, cardMinHeight }">
        <div
          class="pa-2 d-flex flex-column justify-center align-center"
          :style="{
            'min-height':
              cardMinHeight.indexOf('px') != -1
                ? cardMinHeight
                : cardMinHeight + 'px',
            'background-color': item.color,
          }"
        >
          <div class="mt-2">{{ item.name }}</div>

          <v-btn v-if="localDeleteZone" icon class="remove">
            <v-icon @click="remove(item)">mdi-close</v-icon>
          </v-btn>
        </div>
      </template>
    </InternalZoneList>

    <v-subheader class="font-italic font-weight-light">
      Zone Esterne
    </v-subheader>

    <ExternalZoneList
      :items="externalZoneList"
      @input="selectedCard"
      :returnObject="true"
      card-max-height="100"
      card-min-height="100"
      card-max-width="100"
      card-min-width="100"
    >
      <template v-slot:item="{ item, cardMinHeight }">
        <div
          class="pa-2 d-flex flex-column justify-center align-center"
          :style="{
            'min-height':
              cardMinHeight.indexOf('px') != -1
                ? cardMinHeight
                : cardMinHeight + 'px',
            'background-color': item.color,
          }"
        >
          <div class="mt-2">{{ item.name }}</div>

          <v-btn v-if="localDeleteZone" icon class="remove">
            <v-icon @click="remove(item)">mdi-close</v-icon>
          </v-btn>
        </div>
      </template>
    </ExternalZoneList>

    <ZoneUpdateDialog
      v-if="openDetailZone"
      :open-dialog="openDetailZone"
      :zone-id="zoneIdSelected"
      @close="close"
      @updated-zone="reloadList"
    >
    </ZoneUpdateDialog>
  </div>
</template>

<script>
import InternalZoneList from "@/components/common/ResponsiveCardSelector.vue";
import ExternalZoneList from "@/components/common/ResponsiveCardSelector.vue";
import zoneManagement from "@/services/bar/zone.service.js";
import ZoneUpdateDialog from "@/components/bar/settings/zone/edit/ZoneUpdateDialog.vue";
import Vue from "vue";

import zoneActiveHandler from "@/components/bar/handlers/zoneActiveHandler.js";

export default {
  components: {
    InternalZoneList,
    ExternalZoneList,
    ZoneUpdateDialog,
  },
  props: {
    isMinimalized: {
      type: Boolean,
      default: false,
    },
    deleteZone: {
      type: Boolean,
      default: false,
    },
    internalZoneCounter: {
      type: Number,
      default: 0,
    },
    externalZoneCounter: {
      type: Number,
      default: 0,
    },
    bus: {
      type: Object,
      default: () => {
        return new Vue();
      },
    },
  },
  data: function () {
    return {
      openDetailZone: false,
      localIsMinimalize: false,
      localInternalZoneCounter: 0,
      localExternalZoneCounter: 0,
      internalZoneList: [],
      externalZoneList: [],
    };
  },
  mounted() {
    this.localIsMinimalize = this.isMinimalized;
    this.localInternalZoneCounter = this.internalZoneCounter;
    this.localExternalZoneCounter = this.externalZoneCounter;
    this.loadZones();
    this.bus.$on("reload-zones", this.reloadList);

    this.bus.$on("remove-last-internal-zone", () => {
      this.remove(this.internalZoneList.at(-1));
    });
    this.bus.$on("remove-last-external-zone", () => {
      this.remove(this.externalZoneList.at(-1));
    });
  },
  methods: {
    close: function () {
      this.openDetailZone = false;
    },
    reloadList: function () {
      this.close();
      this.loadZones();
      if (this.localIsMinimalize) {
        zoneActiveHandler.loadActiveZones(new Date().getDay());
      }
    },
    selectedCard: function (zoneSelected) {
      if (!this.localDeleteZone) {
        this.openDetailZone = true;
        if (zoneSelected) this.zoneIdSelected = Number(zoneSelected.id);
      }
    },
    loadZones() {
      this.getZoneFiltered({ isInternal: true });
      this.getZoneFiltered({ isInternal: false });
    },
    getZoneFiltered: function (filters) {
      zoneManagement.list(filters).then((data) => {
        if (filters.isInternal) this.internalZoneList = data;
        else this.externalZoneList = data;

        this.localInternalZoneCounter = this.internalZoneList.length;
        this.localExternalZoneCounter = this.externalZoneList.length;
        this.$emit(
          "update:internal-zone-counter",
          this.localInternalZoneCounter
        );
        this.$emit(
          "update:external-zone-counter",
          this.localExternalZoneCounter
        );
      });
    },
    remove(zone) {
      zoneManagement.delete(zone).then(() => {
        this.reloadList();
      });
    },
  },
  computed: {
    localDeleteZone: function () {
      return this.deleteZone;
    },
  },
};
</script>

<style>
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}
</style>