import EventsHandler from "../../../services/common/eventsHandler";
import zoneService from "@/services/bar/zone.service";
import reservationService from "@/services/bar/reservation.service";
import tableUtils from "@/mixins/bar/tableUtils.js";

class ZoneActiveHandler extends EventsHandler {
  constructor() {
    super();
    this._activeZones = [];
    this._reservationsList = [];
  }

  get activeZones() {
    return this._activeZones;
  }

  get reservationsList() {
    return this._reservationsList;
  }

  loadActiveZones(day) {
    return new Promise((resolve, reject) => {
      this._activeZones = [];
      zoneService
        .getDailyZones(day)
        .then(async (zones) => {
          try {
            let key = 0;
            for (const zone of zones) {
              let zoneTables = await zoneService.getZoneTables(Number(zone.id));
              let _tables = [];
              for (let j = 0; j < zoneTables.length; j++) {
                const table = zoneTables[j];
                let zoneBuilt = tableUtils.methods._buildTableForGridLayout(
                  table
                );
                zoneBuilt.zone = zone;
                _tables.push(zoneBuilt);
              }

              this._activeZones.push({
                key: ++key,
                zone: zone,
                tables: _tables,
              });
            }
          } catch (error) {
            reject(error);
          }

          this._executeAsyncCallbacksOf("reload-active-zones", {
            activeZones: [...this._activeZones],
          })
            .then(() => {
              resolve(this._activeZones);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  loadReservations(filters) {
    return new Promise((resolve, reject) => {
      reservationService
        .list(filters)
        .then((reservations) => {
          if (reservations) this._reservationsList = reservations;

          this._executeAsyncCallbacksOf("reload-reservations", {
            reservations: [...this._reservationsList],
          })
            .then(() => {
              resolve(this._reservationsList);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  sortAlphaNum(a, b) {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    var aA = a.code.replace(reA, "");
    var bA = b.code.replace(reA, "");
    if (aA === bA) {
      var aN = parseInt(a.code.replace(reN, ""), 10);
      var bN = parseInt(b.code.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }
}

export default new ZoneActiveHandler();
