<template>
  <div :style="cssVariables">
    <grid-layout
      class="layout-container"
      :layout.sync="elements"
      :col-num="colNum"
      :row-height="elementHeight"
      :is-draggable="draggable"
      :is-resizable="resizable"
      :vertical-compact="!vertical"
      :prevent-collision="localPreventCollision"
      :use-css-transforms="true"
      :margin="marginElement"
      @layout-created="layoutCreatedEvent"
      @layout-before-mount="layoutBeforeMountEvent"
      @layout-mounted="layoutMountedEvent"
      @layout-ready="layoutReadyEvent"
      @layout-updated="layoutUpdatedEvent"
      @breakpoint-changed="breakpointChangedEvent"
    >
      <grid-item
        v-for="el in elements"
        :id="el.i"
        :key="el.key"
        :static="el.static"
        :x="el.x"
        :y="el.y"
        :w="el.w"
        :h="el.h"
        :i="el.i"
        :class="el.type"
        :style="{
          ...el.style,
          'background': !el.selectedToJoin ? getColor(el) : 'yellow',
        }"
        :v-show="el.enabled"
        @resize="resizeEvent"
        @move="moveEvent"
        @resized="resizedEvent"
        @container-resized="containerResizedEvent"
        @moved="movedEvent"
      >
        <slot v-bind="el" name="item-content"> </slot>

        <div @click="click(el)">
          <v-btn
            style="opacity: 1"
            v-if="deletable"
            x-small
            fab
            class="remove"
            @click="remove(el.id)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            style="opacity: 1"
            v-if="splittable"
            x-small
            fab
            class="remove"
            @click="join(el.id)"
          >
            <v-icon>mdi-call-split</v-icon>
          </v-btn>
          <v-btn
            style="opacity: 1"
            v-if="joinable"
            x-small
            fab
            class="remove"
            @click.prevent="join(el)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-btn v-if="disabled" x-small fab class="disable">
            <v-icon v-if="el.enabled" @click="disable(el.id)">mdi-eye</v-icon>
            <v-icon v-else @click="enable(el.id)">mdi-eye-off</v-icon>
          </v-btn>
        </div>
      </grid-item>
    </grid-layout>
  </div>
</template
        >
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import { GridLayout, GridItem } from "vue-grid-layout";

export default {
  components: {
    GridLayout,
    GridItem,
  },
  data () {
    return {
       localPreventCollision: false,
    }
  },
  mounted(){
  },
  props: {
    elements: {
      type: Array,
      default: () => [
        {
          id: 0,
          x: 0,
          y: 0,
          w: 2,
          h: 2,
          i: "T0",
          type: "table-standard",
          enabled: true,
          style: "background-color:#E0E0E0;",
        },
      ],
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    resizable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: true,
    },
    colNum: {
      type: Number,
      default: 12,
    },
    elementHeight: {
      type: Number,
      default: 20,
    },
    marginElement: {
      type: Array,
      default: () => [20, 20],
    },
    getColor: {
      type: Function,
      default: () => { return '#ccc'},
    },
    splittable: {
      type: Boolean,
      default: false,
    },
    joinable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click: function (el) {
      this.$emit("selected-table", el);
    },
    remove: function (id) {
      this.$emit("remove-element", id);
    },
    disable: function (id) {
      this.$emit("disable-element", id);
    },
    join(el){
      el.static = false;
      el.selectedToJoin = true;
      this.elements = [...this.elements];
      this.localPreventCollision = true;
    },
    enable: function (id) {
      this.$emit("enable-element", id);
    },
    /*default Event for GridLayout*/
    layoutCreatedEvent: function (newLayout) {
      // console.log("Created layout: ", newLayout);
      this.$emit("create-layout", newLayout);
    },
    layoutBeforeMountEvent: function (newLayout) {
      // console.log("beforeMount layout: ", newLayout);
      this.$emit("before-create-layout", newLayout);
    },
    layoutMountedEvent: function (newLayout) {
      // console.log("Mounted layout: ", newLayout);
      this.$emit("mounted-layout", newLayout);
    },
    layoutReadyEvent: function (newLayout) {
      // console.log("Ready layout: ", newLayout);
      this.$emit("ready-layout", newLayout);
    },
    layoutUpdatedEvent: function (newLayout) {
      // console.log("Updated layout: ", newLayout);
      this.$emit("updated-layout", newLayout);
    },
    /**
     *
     * @param newBreakpoint the breakpoint name
     * @param newLayout the chosen layout for the breakpoint
     *
     */
    breakpointChangedEvent: function (newBreakpoint, newLayout) {
      this.$emit("breakpoint-event", {
        breakpoint: newBreakpoint,
        layout: newLayout,
      });
      // console.log(
      // "BREAKPOINT CHANGED breakpoint=",
      // newBreakpoint,
      // ", layout: ",
      // newLayout
      // );
    },
    moveEvent: function (i, newX, newY) {
      this.$emit("move-event", { i: i, x: newX, y: newY });
      // console.log("MOVE i=" + i + ", X=" + newX + ", Y=" + newY);
    },
    resizeEvent: function (i, newH, newW, newHPx, newWPx) {
      this.$emit("resize-event", {
        i: i,
        h: newH,
        w: newW,
        h_px: newHPx,
        w_px: newWPx,
      });
      // console.log(
      // "RESIZE i=" +
      // i +
      // ", H=" +
      // newH +
      // ", W=" +
      // newW +
      // ", H(px)=" +
      // newHPx +
      // ", W(px)=" +
      // newWPx
      //);
    },
    movedEvent: function (i, newX, newY) {
      this.$emit("moved-event", { i: i, x: newX, y: newY });
      // console.log("MOVED i=" + i + ", X=" + newX + ", Y=" + newY);
    },
    /**
     *
     * @param i the item id/index
     * @param newH new height in grid rows
     * @param newW new width in grid columns
     * @param newHPx new height in pixels
     * @param newWPx new width in pixels
     *
     */
    resizedEvent: function (i, newH, newW, newHPx, newWPx) {
      this.$emit("resized-event", {
        i: i,
        h: newH,
        w: newW,
        h_px: newHPx,
        w_px: newWPx,
      });
      // console.log(
      //   "RESIZED i=" +
      //     i +
      //     ", H=" +
      //     newH +
      //     ", W=" +
      //     newW +
      //     ", H(px)=" +
      //     newHPx +
      //     ", W(px)=" +
      //     newWPx
      // );
    },
    /**
     *
     * @param i the item id/index
     * @param newH new height in grid rows
     * @param newW new width in grid columns
     * @param newHPx new height in pixels
     * @param newWPx new width in pixels
     *
     */
    containerResizedEvent: function (i, newH, newW, newHPx, newWPx) {
      this.$emit("container-resized-event", {
        i: i,
        h: newH,
        w: newW,
        h_px: newHPx,
        w_px: newWPx,
      });
      // console.log(
      //   "CONTAINER RESIZED i=" +
      //     i +
      //     ", H=" +
      //     newH +
      //     ", W=" +
      //     newW +
      //     ", H(px)=" +
      //     newHPx +
      //     ", W(px)=" +
      //     newWPx
      // );
    },
  },
  computed: {
    cssVariables() {
      return {
        "--background-color": this.backgroundColor,
      };
    },
  },
  watch:{
    elements(newVal) {
      console.log('aggiorno')
    }
  }
};
</script>

<style>
.layout-container {
  border-radius: 8px;
}
.table-standard {
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 40%;
}

.table-standard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.table-circular {
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.table-circular:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.layoutJSON {
  background: #ddd;
  border: 10px solid black;
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}

/*************************************/
.divider-footer {
  /* position: ; */
  bottom: 10;
}
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}
.disable {
  position: absolute;
  left: 2px;
  top: 0;
  cursor: pointer;
}
.vue-grid-layout {
  border: 2px solid black;
  background: var(--background-color);
}
.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
  border: 2px solid black;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  /* background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat; */
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>