import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class zoneManagment {
  list(filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/bar/zones/list", { filters: filters })
        .then((response) => {
          if (response.success) {
            resolve(response.results.rows);
          }
        });
    });
  }

  get(zoneId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + "/bar/zones/get", { id: zoneId }).then((response) => {
        resolve(response.results);
      });
    });
  }

  update(params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/zones/update", params).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject("error during the call");
        }
      });
    });
  }

  create(zone) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/zones/create", zone).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject(response.results.error);
        }
      });
    });
  }

  addTable(zoneId, tableId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/bar/zones/tables/" + zoneId + "/add", {
          tableId: tableId,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            console.log(response);
            reject("error during the call");
          }
        });
    });
  }

  getZoneTables(zoneId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/bar/zones/tables/" + zoneId + "/list")
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            console.log(response);
            reject("error during the call");
          }
        });
    });
  }

  zoneTableListWithReservationPerDay(zoneId, date) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/bar/zones/tables/" + zoneId + "/list/withReservationPerDay/",{date: date})
        .then((response) => {
          if (response.success) {
            resolve(response.results.rows);
          } else {
            console.log(response);
            reject("error during the call");
          }
        });
    });
  }

  listZoneTableCode() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + "/bar/zones/getZoneTableCodes").then((response) => {
        if (response.success) {
          resolve(response.results);
        }
      });
    });
  }

  delete(zone) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!zone.id) {
        reject("id undefined");
      }

      axios
        .get(apiUrl + "/bar/zones/delete", {
          id: zone.id,
        })
        .then((response) => {
          if (!response.success) {
            reject(response.results);
          } else {
            resolve(response);
          }
        });
    });
  }

  getDailyZones(day) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + "/bar/zones/getDailyZones/" + day).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject("error during the call");
        }
      });
    });
  }
}

export default new zoneManagment();
