export default {
  methods: {
    _parsingStyleFromObjToString(obj) {
      let style = "";
      for (const [key, value] of Object.entries(obj)) {
        if (value) style += key.replace("_", "-") + ":" + value + ";";
      }
      return style;
    },
    _parsingStyleFromStringToObj(string) {
      let obj = {};
      let splitString = string.split(";");
      for (let cssToSplit of splitString) {
        let cssSplitted = cssToSplit.split(":");
        if (cssSplitted[0] !== "") {
          let key = cssSplitted[0].replace("-", "_");
          let value = cssSplitted[1];
          obj[key] = value;
        }
      }
      return obj;
    },
    _lastIndex(array) {
      let maxIndex = 0;
      let tableCodeNumbers = array.map((t) => {
        return parseInt(t.i.match(/\d+/));
      });
      if (array) maxIndex = Math.max.apply(Math, tableCodeNumbers);
      maxIndex = array.length == 0 ? 0 : maxIndex;

      return maxIndex;
    },

    _removeTableByType: function(tableArray, type) {
      let _tables = tableArray.filter((table) => table.type == "table-" + type);

      if (_tables.length > 0) {
        let lastIndex = this._lastIndex(_tables);
        this._removeTable(tableArray, lastIndex + 1);
      }
    },
    _removeTable: function(tableArray, tableId) {
      const index = tableArray.map((item) => item.id).indexOf(tableId);
      tableArray.splice(index, 1);
      return tableArray;
    },
    _enableTable: function(tableArray, tableId_clicked) {
      const index = tableArray.map((item) => item.id).indexOf(tableId_clicked);
      tableArray[index].enabled = true;
      this.styleTable = this._parsingStyleFromStringToObj(
        tableArray[index].style
      );
      this.styleTable.opacity = "1";
      tableArray[index].style = this._parsingStyleFromObjToString(
        this.styleTable
      );

      return tableArray;
    },
    _disableTable: function(tableArray, tableId_clicked) {
      const index = tableArray.map((item) => item.id).indexOf(tableId_clicked);
      tableArray[index].enabled = false;
      this.styleTable = this._parsingStyleFromStringToObj(
        tableArray[index].style
      );
      this.styleTable.opacity = "0.4";
      tableArray[index].style = this._parsingStyleFromObjToString(
        this.styleTable
      );
      return tableArray;
    },
    _buildTableObjectFromVuex(isInternal, params) {
      var today = new Date();
      return {
        id: params["id"],
        code: params["i"],
        guests: params["guests"] ? params["guests"] : 0,
        enabled: params["enabled"],
        w: params["w"],
        h: params["h"],
        x: params["x"],
        y: params["y"],
        type: params["type"],
        color: params["color"],
        isInternal: isInternal,
        currentReservationId: params["currentReservationId"],
        createdAt: new Date(today),
      };
    },
    _buildTableForGridLayout(params) {
      return {
        key: Number(params["id"]),
        id: Number(params["id"]),
        x: Number(params["x"]),
        y: Number(params["y"]),
        w: Number(params["w"]),
        h: Number(params["h"]),
        i: params["code"],
        currentReservationId: params["currentReservationId"],
        currentBillId: params["currentBillId"],
        type: params["type"],
        enabled: params["enabled"],
        reservations: !!params.reservations[0].reservationId ? params.reservations : null,
        guests: params["guests"],
        color: params["color"],
      };
    },
  },
};
