<template>
  <div>
    <FullScreenDialog
      persistent
      v-model="localOpenDialog"
      title="Creazione Zona"
      :can-close="false"
    >
      <template v-slot:header-actions>
        <v-text-field
          :hide-details="true"
          class="d-flex justify-center align-center shrink wrap"
          dense
          filled
          disabled
          v-model="zoneTableCode"
          label="Codice Tavolo"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-text-field
          :hide-details="true"
          width="10px"
          class="d-flex justify-center align-center"
          dense
          filled
          v-model="zoneName"
          label="Nome zona"
        ></v-text-field>
        <v-btn icon small class="ml-3" @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-btn icon small :disabled="!canSave" @click="saveZone" class="ml-3"
          ><v-icon>mdi-check</v-icon></v-btn
        >
      </template>
      <template v-slot:content>
        <v-row>
        <v-col cols="9">
          <v-row class="ma-2">
            <v-col cols="2">
              <p class="font-italic font-weight-light">Tipologia</p>
              <v-radio-group v-model="radioBtnIsInternal">
                <v-radio
                  class="font-italic font-weight-light"
                  label="Interna"
                  :value="true"
                >
                </v-radio>

                <v-radio
                  class="font-italic font-weight-light"
                  label="Esterna"
                  :value="false"
                >
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="2">
              <v-row>
                <p class="ma-3 font-italic font-weight-light">Colore Zona</p>
              </v-row>
              <v-row class="ml-4 mt-5">
                <StandardColorPicker
                  v-model="localZoneColorSelected"
                ></StandardColorPicker>
              </v-row>
            </v-col>
            <v-col cols="8">
              <p class="font-italic font-weight-light">
                Giorni Attivi

                <v-btn v-if="!cleanWeek" icon  @click="allSelected"
                  ><v-icon>mdi-calendar-multiple</v-icon></v-btn
                >
                <v-btn v-else icon  @click="allSelected"
                  ><v-icon>mdi-calendar-remove</v-icon></v-btn
                >
              </p>
              <WeekSelector
                :items="week"
                v-model="weekEnablings"
                item-key="key"
                :multiple="true"
                card-max-height="10"
                card-min-height="10"
                card-max-width="50"
                card-min-width="50"
              >
                <template v-slot:item="{ item, cardMinHeight }">
                  <div
                    class="pa-2 flex flex-column justify-center align-center"
                    :style="{
                      'min-height':
                        cardMinHeight.indexOf('px') != -1
                          ? cardMinHeight
                          : cardMinHeight + 'px',
                    }"
                  >
                    <div class="mt-2">{{ item.name }}</div>
                  </div>
                </template>
              </WeekSelector>
            </v-col>

            <v-col cols="12">
              <TablesGridLayout
                :background-color="localZoneColorSelected"
                :elements="tablesToCreate"
                :disabled="disabled"
                :draggable="draggable"
                :resizable="resizable"
                :deletable="deletable"
                :vertical="vertical"
                :margin-element="[10, 10]"
                :element-height="30"
                :colNum="colNum"
                @remove-element="handleRemoveElement"
                @disable-element="handleDisableElement"
                @enable-element="handleEnableElement"
              >
                <template v-slot:item-content="el">
                  <div class="text d-flex justify-center align-center">
                    {{ el.i }}
                  </div>
                </template>
              </TablesGridLayout>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <RightMenuSettingTable
            @open-create="localOpenDialog = true"
            @add="handleAdding"
            @remove="handleRemoving"
            @deletable="handleDeletable"
            @disable="handleDisable"
            @draggable="handleDraggable"
            @resizable="handleResizable"
            @change-color-circular="handleChangeColorCircular"
            @change-color-standard="handleChangeColorStandard"
          ></RightMenuSettingTable>
        </v-col>
      </v-row>
      </template>
      <template v-slot:footer-actions>
        <v-btn text color="error" @click="close">Annulla</v-btn>
        <v-btn text :disabled="!canSave" @click="saveZone">Salva</v-btn>
      </template>
    </FullScreenDialog>

    <ErrorDialog
      dialog-width="360"
      dialog-height="360"
      persistent
      v-model="showErrorDialog"
      title="Errore"
    >
      {{ errorMessage }}
    </ErrorDialog>
  </div>
</template>

<script>
import WeekSelector from "@/components/common/ResponsiveCardSelector.vue";

import TablesGridLayout from "@/components/common/GridLayoutComponent.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import ErrorDialog from "@/components/common/StandardDialog.vue";
import RightMenuSettingTable from "@/components/bar/settings/zone/table/RightMenuSettingTable.vue";
import StandardColorPicker from "@/components/common/StandardColorPicker.vue";

import zoneService from "@/services/bar/zone.service";
import tableService from "@/services/bar/table.service";
import tableUtils from "@/mixins/bar/tableUtils";

export default {
  name: "ZoneCreateDialog",
  mixins: [tableUtils],
  components: {
    WeekSelector,
    StandardDialog,
    ErrorDialog,
    TablesGridLayout,
    RightMenuSettingTable,
    StandardColorPicker,
    FullScreenDialog,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    zoneColorSelected: {
      type: String,
      default: "",
    },
    zoneInternal: {
      type: Boolean,
      default: "",
    },
    zoneCounter: {
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
      errorMessage: "",
      showErrorDialog: false,
      radioBtnIsInternal: false,
      canSave: false,
      isInternal: false,
      isExternal: false,
      localZoneColorSelected: undefined,
      weekEnablings: undefined,
      zoneName: "",
      index: 0,
      tablesToCreate: [],
      localOpenDialog: false,
      colorCircularTable: "",
      colorStandardTable: "",
      draggable: true,
      resizable: true,
      deletable: false,
      disabled: false,
      vertical: true,
      colNum: 12,
      week: [
        { key: 0, name: "Dom" },
        { key: 1, name: "Lun" },
        { key: 2, name: "Mar" },
        { key: 3, name: "Mer" },
        { key: 4, name: "Gio" },
        { key: 5, name: "Ven" },
        { key: 6, name: "Sab" },
      ],
      zoneTableCode: "",
      zoneTableCodeList: [],
      alphabet: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      styleTable: {
        background_color: "",
        opacity: "",
      },
      cleanWeek: false,
    };
  },
  mounted() {
    this.localOpenDialog = this.openDialog;
    this.localZoneColorSelected = this.zoneColorSelected;
    this.radioBtnIsInternal = this.zoneInternal;
    this.getZoneTableCodes();
  },
  methods: {
    handleRemoveElement: function (id) {
      this._removeTable(this.tablesToCreate, id);
    },
    handleDisableElement: function (id) {
      this._disableTable(this.tablesToCreate, id);
    },
    handleEnableElement: function (id) {
      this._enableTable(this.tablesToCreate, id);
    },
    handleChangeColorStandard: function (newColor) {
      this.colorStandardTable = newColor;
    },
    handleChangeColorCircular: function (newColor) {
      this.colorCircularTable = newColor;
    },
    handleAdding: function (type) {
      this.addTable(type);
    },
    handleRemoving: function (type) {
      this._removeTableByType(type);
    },
    handleDisable: function (value) {
      this.disabled = value;
    },
    handleDraggable: function (value) {
      this.draggable = value;
    },
    handleResizable: function (value) {
      this.resizable = value;
    },
    handleDeletable: function (value) {
      this.deletable = value;
    },
    removeTableByType: function (type) {
      this._removeTableByType(this.tablesToCreate, type);
    },
    enableTable: function (idTable_clicked) {
      this._enableTable(this.tablesToCreate, idTable_clicked);
    },
    disableTable: function (idTable_clicked) {
      this._disableTable(this.tablesToCreate, idTable_clicked);
    },
    updateField(nameField, value) {
      this.$store.dispatch("bar/zones/updateField", {
        field: nameField,
        value: value,
      });
    },
    close: function () {
      this.$emit("close");
    },
    getZoneTableCodes() {
      zoneService
        .listZoneTableCode()
        .then((zoneTableCodes) => {
          this.zoneTableCodeList = zoneTableCodes;
          this.createTableCode();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createTableCode() {
      if (this.zoneTableCodeList.length > 0) {
        for (let i = 0; i < this.alphabet.length; i++) {
          const letter = this.alphabet[i];
          if (
            this.zoneTableCodeList.filter((e) => e.tableCode === letter)
              .length == 0
          ) {
            this.zoneTableCode = letter;
            break;
          }
        }
      } else this.zoneTableCode = this.alphabet[0];

      //let localZoneCounter = this.zoneCounter - 1;
      /*       if (localZoneCounter > 25) {
  let letterRep = (localZoneCounter / 26).toFixed();
        for (let i = 0; i <= letterRep; i++) {
          finalString += this.alphabet[localZoneCounter - 26];
        }
      }
      else 
        finalString = this.alphabet[localZoneCounter]; */
      //return finalString;
    },
    addTable: function (type) {
      let tableColorSelected = type.includes("standard")
        ? this.colorStandardTable
        : this.colorCircularTable;

      let lastElement =
        this.tablesToCreate.length > 0
          ? this.tablesToCreate[this.tablesToCreate.length - 1]
          : undefined;

      let lastId = this._lastIndex(this.tablesToCreate);
      let lastY = lastElement ? lastElement["y"] : 0;
      lastId++;

      this.styleTable.background_color = tableColorSelected
        ? tableColorSelected
        : "";

      let styleString = this._parsingStyleFromObjToString(this.styleTable);

      this.tablesToCreate.push({
        x: (this.tablesToCreate.length * 2) % (this.colNum || 12),
        y: lastY,
        w: 2,
        h: 2,
        i: this.zoneTableCode + lastId++,
        id: Number(lastId),
        type: type,
        enabled: true,
        style: styleString,
        color: tableColorSelected,
      });

      this.updateField("tables", this.tablesToCreate[0]);
    },
    allSelected() {
      if (!this.cleanWeek) {
        this.weekEnablings = this.week.map((item) => item.key);
      } else this.weekEnablings = [];
      this.cleanWeek = !this.cleanWeek;
    },
    saveZone: function () {
      let zoneToCreate = this._buildZoneObject();
      zoneService
        .create(zoneToCreate)
        .then((createdZone) => {
          //in questo modo oppure con una chiamata singola
          this.insertionTablesObject.forEach((tableToBuild) => {
            let table = this._buildTableObjectFromVuex(
              createdZone.isInternal,
              tableToBuild
            );
            tableService
              .create(table)
              .then((createdTable) => {
                zoneService
                  .addTable(createdZone.id, createdTable.id)
                  .then(() => {
                    this.$emit("saved-zone");
                  })
                  .catch((err) => {
                    this.errorMessage = err;
                    this.showErrorDialog = true;
                  });
              })
              .catch((err) => {
                this.errorMessage = err;
                this.showErrorDialog = true;
              });
          });
        })
        .catch((err) => {
          this.errorMessage = err;
          this.showErrorDialog = true;
        });
    },
    _buildZoneObject() {
      var today = new Date();
      return {
        name: this.insertionZone.name,
        tableCode: this.zoneTableCode,
        w: 0,
        h: 0,
        isInternal: this.radioBtnIsInternal,
        color: this.localZoneColorSelected ? this.localZoneColorSelected : "",
        weekEnablings: this.insertionZone.weekEnablings,
        createdAt: new Date(today),
      };
    },
  },
  computed: {
    insertionTablesObject() {
      return this.$store.state.bar.zones.data.zone.tables;
    },
    insertionZone() {
      return this.$store.state.bar.zones.data.zone;
    },
  },
  watch: {
    zoneName(newVal) {
      this.updateField("name", newVal);
      if (this.zoneName && this.tablesToCreate.length > 0) this.canSave = true;
      else this.canSave = false;
    },
    tablesToCreate(newVal) {
      this.updateField("tables", newVal);
      if (this.zoneName && this.tablesToCreate.length > 0) this.canSave = true;
      else this.canSave = false;
    },
    weekEnablings(newVal) {
      this.updateField("weekEnablings", newVal);
    },
  },
};
</script>

<style>
</style>